@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.image {
  //Style for a single image (ratio 16:9)
  aspect-ratio: 16 / 9;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;

  //Style for multiple images (ratio 1:1)
  :global(.mediaMultiple) & {
    aspect-ratio: 1 / 1;
  }
}
